<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="100px"
      class="demo-ruleForm"
      style="padding: 0 109px"
    >
      <el-form-item :label="$t('System.IosConfig.UpdateTime') + '：'">
        <span style="color: #807e7e">{{ dataArray.updateTime }}</span>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.VersionNumber') + '：'">
        <span style="color: #807e7e">{{ dataArray.versionName }}</span>
      </el-form-item>
      <el-form-item
        :label="$t('System.Windowsconfiguration.Downloadexe') + '：'"
      >
        <el-link
          @click="downFile('protected/config/exe/esm.exe')"
          type="primary"
          >{{ dataArray.name }}</el-link
        >
      </el-form-item>
      <el-form-item class="form-item" label="esm.exe：">
        <el-input
          v-model="form.appName"
          style="width: 200px; margin: 0 10px 0 0"
          :disabled="true"
          size="small"
        >
        </el-input>
        <el-upload
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :on-success="handleAvatarSuccess1"
          :on-error="handleUpError"
          class="el-button mar-class-8"
          style="padding: 0; border: none"
          :on-change="changeUpload"
          multiple
          accept=".exe"
        >
          <el-button
            size="small"
            style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            "
          >
            <span
              style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              "
            >
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{
              $t("System.IosConfig.SelectApp")
            }}</span>
          </el-button>
        </el-upload>
        <el-progress
          v-if="flagStatus"
          :percentage="percentage"
          :color="customColorMethod"
          style="width: 230px"
        ></el-progress>
      </el-form-item>
      <el-form-item :label="$t('System.IosConfig.ForceUpdates') + '：'">
        <el-checkbox true-label="1" false-label="0" v-model="form.isForce">
          <span>{{ $t("System.IosConfig.MessagePort") }}</span>
        </el-checkbox>
      </el-form-item>
    </el-form>
    <el-button
      style="width: 80px; margin: 20px 0 0 210px"
      @click="saveSubmit"
      size="small"
      type="primary"
      >{{ $t("public.Save") }}</el-button
    >
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: {
    dialogInfo,
  },
  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error(this.$t("System.IosConfig.Error")));
      } else {
        callback();
      }
    };
    return {
      // 导入
      flagStatus: false,
      percentage: 0,
      titleInfo: "",
      visibaelFlag: false,
      form: {
        // 系统权限限制
        use: false,
        appName: "",
        isForce: "",
      },
      rules: {
        appName: [
          {
            required: true,
            message: this.$t("System.IosConfig.CheckDel"),
            trigger: "blur",
          },
          { validator: validateInput, trigger: ["blur", "change"] },
        ],
      },
      dataArray: {},
    };
  },
  created() {
    this.selectSearch();
  },
  methods: {
    async downFile(url) {
      var param = {
        path: url,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      console.log(res);
      await this.$axios.downloadFile(res.data);
    },
    async saveSubmit() {
      // if (this.form.appName == "") {
      //   // this.visibaelFlag = true
      //   // this.titleInfo = "请上传文件"
      //   this.$message({
      //     message: "请上传文件",
      //     type: "error",
      //     offset: 100,
      //   });
      //   return;
      // }
      const res = await this.$axios.post(
        "/httpServe/windows/client/update",
        {
          isForce: this.form.isForce,
        },
        true
      );
      if (res.data == 1) {
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
        this.selectSearch();
      }
    },
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    async selectSearch() {
      const res = await this.$axios.post(
        "/httpServe/windows/client/selectOne",
        {},
        true
      );
      console.log(res);
      this.dataArray = res.data;
      console.log(this.dataArray,'this.dataArray');
      this.$set(this.form, "isForce", this.dataArray.isForce + "");
      this.$set(this.form, "appName", this.dataArray.name);
      console.log(this.form,'this.form');
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    //文件上传接口
    async changeUpload(file) {
      console.log(file.progress);
      this.percentage = 0;
      this.flagStatus = true;
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message.warning(this.$t("System.IosConfig.Error2"));
        return false;
      }
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ["exe"]; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.flagStatus = false;
        this.$message({
          message: this.$t("System.IosConfig.Error3"),
          type: "error",
          duration: 2000,
        });
      } else {
        var renameFile = new File([file.raw], "esm.exe", {
          type: file.raw.type,
        });
        const form = new FormData();
        // 文件对象
        form.append("files", renameFile);
        form.append("type", "protected");
        form.append("fileType", "exe");
        form.append("moduleName", "config");
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        this.path = res.data.data[0];
        console.log(res.data,file.name, "99999999999999999");
        if (res.data.code == 200) {
          this.$message({
            message: this.$t("public.appUpsuccessfully"),
            type: "success",
            duration: 3000,
          });
        } else {
          // this.$refs.form.clearFiles();
          this.$message({
            message: this.$t("public.appUpfail"),
            type: "error",
            offset: 100,
          });
        }
        this.$nextTick(() => {
          // this.ruleForm.appName = ;
          this.$set(this.form, "appName", file.name);
          this.flagStatus = false;
        });
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100);
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    handleAvatarSuccess1() {
      this.$refs.form.clearFiles();
      this.$message({
        message: this.$t("public.appUpsuccessfully"),
        type: "success",
        offset: 100,
      });
    },
    handleUpError() {
      this.$refs.form.clearFiles();
      this.$message({
        message: this.$t("public.appUpfail"),
        type: "error",
        offset: 100,
      });
    },
    determine() {
      this.visibaelFlag = false;
    },
    handleClose() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px !important;
}

.form-item {
  margin-bottom: 17px !important;
}

::v-deep .el-form-item__label {
  width: 100px !important;
  font-size: 14px;
}
</style>
